import React, { Component } from 'react';
import styled from 'styled-components';
import Utils from '../Three/handlers/utils';

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Inline = styled.img`
  width: auto;
  height: 2rem;
  width: 50%;
  height: 6vh;
  object-fit: contain;
`;

export default class RetailerLogo extends Component {
  constructor() {
    super();

    this.onClick = () => {
      const { onClick } = this.props;
      if (onClick) {
        onClick();
      }
    };
  }

  render() {
    const { inline } = this.props;
    const retailer = Utils.getURLParameter('retailer');
    const colour = Utils.getURLParameter('colour');
    const variant = Utils.getURLParameter('variant');
    const abTest = Utils.getURLParameter('abTest');
    console.log(abTest);
    let endpointLink;

    const correctLogo = () => {
      const currentURL = window.location.href;
      const imgURL = '/assets/images/';
      return imgURL + retailer + '.png';
    };

    const correctLink = () => {
      //COLLIDER
      if(variant === 'collider') {
        //BLACK
        if (colour === 'black' && retailer === 'bol') {
          return 'https://www.bol.com/nl/p/fossil-collider-hybrid-hr-smartwatch-ftw7010-zwart/9200000118085456/';
        }

        if (colour === 'black' && retailer === 'coolblue') {
          return 'https://www.coolblue.nl/product/837566/fossil-collider-hybrid-hr-smartwatch-ftw7010-zwart.html';
        }

        if (colour === 'black' && retailer === 'brandfield') {
            return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7010';
        }

        if (colour === 'black' && retailer === 'mediamarkt') {
          return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7010';
        }

        //BROWN
        if (colour === 'brown' && retailer === 'bol') {
          return 'https://www.bol.com/nl/p/fossil-collider-hybrid-hr-smartwatch-ftw7008-bruin/9200000118085450/';
        }

        if (colour === 'brown' && retailer === 'coolblue') {
          return 'https://www.coolblue.nl/product/837564/fossil-collider-hybrid-hr-smartwatch-ftw7008-bruin.html';
        }

        if (colour === 'brown' && retailer === 'brandfield') {
            return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7008';
        }

        if (colour === 'brown' && retailer === 'mediamarkt') {
          return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7008';
        }

        //GREY
        if (colour === 'grey' && retailer === 'bol') {
          return 'https://www.bol.com/nl/p/fossil-collider-hybrid-hr-smartwatch-ftw7009-grijs/9200000118085482/';
        }

        if (colour === 'grey' && retailer === 'coolblue') {
          return 'https://www.coolblue.nl/product/837565/fossil-collider-hybrid-hr-smartwatch-ftw7009-grijs.html';
        }

        if (colour === 'grey' && retailer === 'brandfield') {
          if(abTest !== undefined) {
            return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7009?utm_campaign=fossilar';
          } else {
            return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7009';
          }
        }

        if (colour === 'grey' && retailer === 'mediamarkt') {
          return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7009';
        }
      } 

      //MONROE
      if(variant === 'monroe') {
        //BLACK
        if (colour === 'black' && retailer === 'bol') {
          return 'https://www.bol.com/nl/p/fossil-hybrid-hr-smartwatch-ftw7035/9300000009431928/';
        }

        if (colour === 'black' && retailer === 'coolblue') {
          return 'https://www.coolblue.nl/product/868986/fossil-monroe-hybrid-hr-ftw7035-rosegoud-zwart.html';
        }

        if (colour === 'black' && retailer === 'brandfield') {
            return 'https://www.brandfield.nl/fossil-monroe-hybrid-smartwatch-ftw7035';
        }

        if (colour === 'black' && retailer === 'mediamarkt') {
          return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7010';
        }

        //ROSE GOLD
        if (colour === 'rosegold' && retailer === 'bol') {
          return 'https://www.bol.com/nl/p/fossil-hybrid-hr-smartwatch-ftw7039/9300000009431933/';
        }

        if (colour === 'rosegold' && retailer === 'coolblue') {
          return 'https://www.coolblue.nl/product/868987/fossil-monroe-hybrid-hr-ftw7039-rosegoud.html';
        }

        if (colour === 'rosegold' && retailer === 'brandfield') {
            return 'https://www.brandfield.nl/fossil-monroe-hybrid-smartwatch-ftw7039';
        }

        if (colour === 'rosegold' && retailer === 'mediamarkt') {
          return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7008';
        }

        //SILVER
        if (colour === 'silver' && retailer === 'bol') {
          return 'https://www.bol.com/nl/p/fossil-hybrid-hr-smartwatch-ftw7040/9300000009431932/';
        }

        if (colour === 'silver' && retailer === 'coolblue') {
          return 'https://www.coolblue.nl/product/868988/fossil-monroe-hybrid-hr-ftw7040-zilver.html';
        }

        if (colour === 'silver' && retailer === 'brandfield') {
            return 'https://www.brandfield.nl/fossil-monroe-hybrid-smartwatch-ftw7040';
        }

        if (colour === 'silver' && retailer === 'mediamarkt') {
          return 'https://www.brandfield.nl/fossil-monroe-hybrid-smartwatch-ftw7040';
        }
      } 


      //NEUTRA
      if(variant === 'neutra') {
        //BLACK
        if (colour === 'black' && retailer === 'bol') {
          return 'https://www.bol.com/nl/p/fossil-neutra-hybrid-hr-ftw7027-smartwatch-heren-45-mm-zwart/9300000009431931/';
        }

        if (colour === 'black' && retailer === 'coolblue') {
          return 'https://www.coolblue.nl/product/868990/fossil-neutra-hybrid-hr-ftw7027-zwart.html';
        }

        if (colour === 'black' && retailer === 'brandfield') {
            return 'https://www.brandfield.nl/fossil-neutra-hybrid-smartwatch-ftw7027';
        }

        if (colour === 'black' && retailer === 'mediamarkt') {
          return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7010';
        }

        //BROWN
        if (colour === 'brown' && retailer === 'bol') {
          return 'https://www.bol.com/nl/p/fossil-neutra-hybrid-hr-ftw7025-smartwatch-heren-45-mm-bruin/9300000009431927/';
        }

        if (colour === 'brown' && retailer === 'coolblue') {
          return 'https://www.coolblue.nl/product/868989/fossil-neutra-hybrid-hr-ftw7025-goud.html';
        }

        if (colour === 'brown' && retailer === 'brandfield') {
            return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7008';
        }

        if (colour === 'brown' && retailer === 'mediamarkt') {
          return 'https://www.brandfield.nl/fossil-collider-hybrid-smartwatch-ftw7008';
        }
      } 
    };



    return (
      <>
        {inline ? <Inline src={correctLogo()} alt="Fossil AR" onClick={() => {
          window.open(correctLink(), '_blank');
        }} /> : <Image src={correctLogo()} alt="Fossil AR" onClick={() => {
          window.open(correctLink(), '_blank');
        }} />}
      </>
    );
  }
}
