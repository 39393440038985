import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import Logo from '../Logo';

const baseStyles = `
  z-index: 1000;
  position: relative;
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  width: 100vw;
  margin: auto;
  padding: 2rem;
  justify-content: center;
  align-items: center;
`;

const TopContainer = styled.div`
  top: 50%;
  ${baseStyles}
  transform: translateY(-50%);
  transition: top 1500ms ease-in-out, transform 1500ms ease-in-out;

  &.loaded {
    transform: translateY(0);
    top: 0;
  }
`;

const MiddleContainer = styled.div`
  bottom: 6rem;
  ${baseStyles}
  opacity: 1;
  transition: opacity 900ms linear;

  &.loaded {
    opacity: 0;
  }

  img {
    max-width: 70px;
    height: auto;
  }

  p {
    font-size: 1.45em;
    margin: 0.25em 0;
  }
`;

const BottomContainer = styled.div`
  bottom: 0;
  ${baseStyles}

`;

const LogoContainer = styled.div`
  max-width: 160px;
`;

const Loading = (props) => {
  const { load, onClickPlay } = props;

  return (
    <>
      <TopContainer className={load ? 'loading' : 'loaded'}>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </TopContainer>

      <MiddleContainer className={load ? 'loading' : 'loaded'}>
        <img
          alt="Powered by 8th Wall"
          src="/assets/images/8thwall.png"
        />
      </MiddleContainer>

      <MiddleContainer className={load ? 'loaded' : 'loading'}>
        <p>Press to start</p>
      </MiddleContainer>


      <BottomContainer>
        {load ? <Button big icon="spinner" /> : <Button big icon="play" onClick={() => { if (onClickPlay) onClickPlay(); }} />}
      </BottomContainer>
    </>
  );
};

export default Loading;
