import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as THREE from 'three';
import Hammer from 'hammerjs';
import ReactGA from 'react-ga';

import {
  Easing,
  Tween,
  update as TweenUpdate,
} from 'es6-tween';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { getState } from '../../redux/selectors';
import {
  toggleSection,
  selectBiome,
  resetGlobe,
  placeGlobe,
  setMessage,
  closeMessage,
  setHelpDisplayed,
} from '../../redux/actions';
import CameraPicture from '../Interface/CameraPicture';
import Palette from '../../libs/Palette/index';
import MeshHandler from './handlers/mesh';
import Utils from './handlers/utils';

const COORDS_ALT = [
  {
    name: 'hotspot-1',
    component: 'Ocean',
  },
  {
    name: 'hotspot-2',
    component: 'TropicalRainforest',
  },
  {
    name: 'hotspot-3',
    component: 'TemperateRainforest',
  },
  {
    name: 'hotspot-4',
    component: 'Freshwater',
  },
  {
    name: 'hotspot-5',
    component: 'Chaparral',
  },
];
const GLOBE_Y_CENTER = 0.3;

const Canvas = styled.canvas`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

class GlobeAR extends Component {
  constructor() {
    super();
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.listener = null;
    this.audioInteraction = null;
    this.audioBg = null;
    this.fakeEarth = null;
    this.earth = null;
    this.biomes = null;
    this.sunlight = null;
    this.ambLight = null;
    this.sunlight2 = null;
    this.globe = new THREE.Group();
    this.group = new THREE.Group();
    this.hotspots = new THREE.Group();
    this.hotspots.rotation.set(0, Math.PI, 0);
    this.hotspots.position.setZ(0);
    //this.hotspots.position.setY(this.hotspots.position.y - 0.005);
    this.hotspots.scale.set(1.2, 1.2, 1.2);
    this.innerHotspots = new THREE.Group();
    this.clock = new THREE.Clock();
    this.animations = {};
    this.surface = null;
    this.controls = null;
    this.el = React.createRef();
    this.image = false;
    this.statePlace = true;
    this.rotating = true;
    this.screenshot = null;
    this.mixer = null;
    this.raycaster = new THREE.Raycaster();
    this.tapPosition = new THREE.Vector2();
    this.vec3 = new THREE.Vector3();
    this.helpTimeout = null;
    this.theta = 0;

    this.clipPlanes = [
      new THREE.Plane(),
      new THREE.Plane(),
    ];

    this.planeNormal = [
      new THREE.Vector3(),
      new THREE.Vector3(),
    ];

    this.planePoint = [
      new THREE.Vector3(),
      new THREE.Vector3(),
    ];

    this.axis = new THREE.Vector3(1, 0, 0);

    this.sectionRotation = 0;

    this.saved = {
      rotation: null,
      scale: 7.5,
      touches: [],
      vector: new THREE.Vector3(),
    };

    this.state = {
      image: false,
    };

    this.takeScreenshot = () => {
      const { XR8 } = window;
      XR8.CanvasScreenshot.takeScreenshot().then(
        (data) => {
          this.setState({
            image: `data:image/jpeg;base64,${data}`,
          });
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.warn('Screenshot failed:', error);
        },
      );
    };

    this.removeScreenshot = () => {
      this.setState({
        image: false,
      });
    };


    this.attachHandlers = () => {
      const canvas = this.el.current;
      const hammer = new Hammer(canvas);

      hammer.on('tap', (e) => {
        const { props } = this;
        const { store } = props;
        const { app, ui } = store;

        ReactGA.event({
          category: 'ui',
          action: 'interaction',
          label: 'tap',
        }, ['eyekandy', 'internal']);

        this.rotating = false;

        if (app.globePlaced === false) {
          this.spawnEarth();

          if (app.helpDisplayed === false) {
            this.displayHelp();
          } else {
            props.closeMessage();
          }
          props.placeGlobe();
        } else {
          // If placed, raycast to entire globe group.
          // Calculate tap position in normalized device coordinates (-1 to +1) for both components.
          this.tapPosition.x = (e.center.x / window.innerWidth) * 2 - 1;
          this.tapPosition.y = -(e.center.y / window.innerHeight) * 2 + 1;

          // Update the picking ray with the camera and tap position.
          this.raycaster.setFromCamera(this.tapPosition, this.camera);

          if (ui.biome === false && ui.section === false) {
            const intersects = this.raycaster.intersectObjects([this.earth.children[0], this.hotspots], true);
            if (intersects.length > 0
              && (intersects[0].object.parent.name === 'hotspot')) {
              const { userData } = intersects[0].object.parent;
              const { component } = userData;
              props.selectBiome(component);
            }
          }
        }
      });

      hammer.get('pinch').set({ enable: true });
      hammer.get('rotate').set({ enable: false });
      hammer.get('pan').set({ enable: true, threshold: 5 });

      hammer.on('pan', (e) => {
        const { props } = this;
        const { store } = props;
        const { ui } = store;
        if (ui.section === true) {
          return;
        }

        const {
          velocityX, velocityY, deltaX, deltaY,
        } = e;
        const { movementX, movementY } = e.srcEvent;
        const delta = {
          x: deltaX,
          y: deltaY,
        };

        // if (movementX && typeof movementX !== 'undefined') {
        //   this.group.rotation.y += (movementX / 1000) * 3;
        // } else {
        //   this.group.rotation.y += (velocityX / 15);
        // }

        // if (movementY && typeof movementY !== 'undefined') {
        //   this.theta = (movementY / 1000) * -3;
        // } else {
        //   this.theta = (velocityY / 15) * -1;
        // }

        if (movementX && movementY) {
          delta.x = (movementX / 15);
          delta.y = (movementY / 15);
        } else {
          delta.x = (velocityX / 0.425);
          delta.y = (velocityY / 0.425);
        }

        const deltaRotationQuaternion = new THREE.Quaternion()
          .setFromEuler(new THREE.Euler(
            THREE.MathUtils.degToRad(delta.y * 5),
            THREE.MathUtils.degToRad(delta.x * 5),
            0,
            'XYZ',
          ));

        this.globe.quaternion.multiplyQuaternions(deltaRotationQuaternion, this.globe.quaternion);
        // this.globe.rotateOnAxis(this.axis, this.theta);
      });

      hammer.on('pinchstart', (e) => {
        const { scale } = e;
        this.saved.scale = scale;
      });

      hammer.on('pinch', (e) => {
        const { scale } = e;
        const effective = scale - this.saved.scale;
        const { x } = this.globe.scale;
        const final = x + effective;
        // if (final > 3 || final < 0.1) {
        //   return;
        // }

        this.globe.scale.set(final, final, final);
        this.earthSectionLeft.scale.set(final, final, final);
        this.earthSectionRight.scale.set(final, final, final);
        this.innerHotspots.scale.set(final, final, final);
        this.saved.scale = scale;
      });

      hammer.on('panend', () => {
        ReactGA.event({
          category: 'ui',
          action: 'interaction',
          label: 'rotate',
        }, ['eyekandy', 'internal']);
      });

      hammer.on('pinchend', () => {
        ReactGA.event({
          category: 'ui',
          action: 'interaction',
          label: 'scale',
        }, ['eyekandy', 'internal']);
      });
    };

    this.displayHelp = (index = 0) => {
      const { props } = this;
      const messages = [
        'INSTRUCTIONS_SWIPE',
        'INSTRUCTIONS_PINCH',
        'INSTRUCTIONS_TAP',
        'INSTRUCTIONS_ICON',
      ];
      const current = messages[index];
      props.closeMessage(true);

      this.helpTimeout = setTimeout(() => {
        props.setMessage(current);

        setTimeout(() => {
          if (typeof messages[index + 1] !== 'undefined') {
            this.displayHelp(index + 1);
          } else {
            props.closeMessage();
            props.setHelpDisplayed();
          }
        }, 3000);
      }, 350);
    };

    this.spawnEarth = () => {
      const { x: gX, z: gZ } = this.group.position;
      this.earthSectionLeft.position.set(gX, GLOBE_Y_CENTER, gZ);
      this.earthSectionRight.position.set(gX, GLOBE_Y_CENTER, gZ);
      this.innerHotspots.position.set(gX, GLOBE_Y_CENTER, gZ);
      this.globe.rotation.x = 0;
      this.globe.rotation.y = THREE.MathUtils.degToRad(180);
      this.globe.rotation.z = 0;
      const scale = { x: 0.0001, y: 0.0001, z: 0.0001 };
      this.globe.scale.set(0.0001, 0.0001, 0.0001);
      this.globe.visible = true;
      //this.globe.rotation.y = THREE.MathUtils.degToRad(180);
      // Force childs to be visible
      this.earth.visible = true;
      this.hotspots.visible = true;
      this.ring.visible = false;
      this.fakeEarth.visible = false;

      this.group.rotation.y = this.camera.rotation.y;

      new Tween(scale)
        .to({ x: 7.5, y: 7.5, z: 7.5 }, 750)
        .easing(Easing.Quadratic.Out)
        .on('update', ({ x, y, z }) => { this.globe.scale.set(x, y, z); })
        .on('complete', () => {
          this.earthSectionLeft.scale.set(1, 1, 1);
          this.earthSectionRight.scale.set(1, 1, 1);
          this.innerHotspots.scale.set(1, 1, 1);
          //this.globe.rotation.y = THREE.MathUtils.degToRad(0);
        })
        .start()
        .delay(2000);

      ReactGA.event({
        category: 'app',
        action: 'globe',
        label: 'place',
      }, ['eyekandy', 'internal']);
    };

    this.updateSections = (scaleValue, reset = false) => {
      // Clamp it so we avoid overlapping the meshes;
      // const d = Math.min(180, Math.max(0, degrees));

      // const [planeLeft, planeRight] = this.clipPlanes;
      // const [normalLeft, normalRight] = this.planeNormal;
      // const [pointLeft, pointRight] = this.planePoint;

      
      if (reset !== true) {
        this.globe.scale.set(scaleValue, scaleValue, scaleValue);
        this.saved.scale = scaleValue;
        // this.earthSectionLeft.rotation.y = 0;
        // normalLeft.set(0, 0, -1).applyQuaternion(this.earthSectionLeft.quaternion);
        // pointLeft.copy(this.group.position);
        // planeLeft.setFromNormalAndCoplanarPoint(normalLeft, pointLeft);
        // this.innerHotspots.rotation.y = this.earthSectionLeft.rotation.y + THREE.MathUtils.degToRad(90);
      }

      // this.earthSectionRight.rotation.y = THREE.MathUtils.degToRad(-180 + d);

      // normalRight.set(0, 0, -1).applyQuaternion(this.earthSectionRight.quaternion);
      // pointRight.copy(this.group.position);
      // planeRight.setFromNormalAndCoplanarPoint(normalRight, pointRight);
    };

    this.globePipelineModule = () => {
      const { XR8 } = window;

      const centerCamera = new THREE.Vector2(0, 0);

      // const placeObjectOnPlanet = (coords) => {
      //   const sprite = coords.component === 'Section' ? 'globe' : 'info';
      //   const hotspot = MeshHandler.hotspotGeometry(coords, 2, 3, sprite);
      //   this.hotspots.add(hotspot);
      // };

      const placeObjectOnWatch = (data) => {
        const {name, component} = data;
        const sprite = component === 'Section' ? 'globe' : 'info';
        const hotspot = MeshHandler.hotspotGeometryAlt.call(this, name, component, sprite);
        this.hotspots.add(hotspot);
      };

      // const placeObjectInsidePlanet = (coords, index) => {
      //   const { component, distance } = coords;
      //   const hotspot = MeshHandler.hotspotGeometry(
      //     {
      //       lat: 85 - (index * 25),
      //       lon: 2,
      //       component,
      //     }, distance, 2.85, 'info',
      //   );
      //   this.innerHotspots.add(hotspot);
      // };

      const initXrScene = () => {
        this.listener = new THREE.AudioListener();
        this.camera.add(this.listener);

        // create a global audio sources
        this.audioInteraction = new THREE.Audio(this.listener);
        this.audioBg = new THREE.Audio(this.listener);
        this.scene.add(this.group);

        // Surface invisible area
        this.surface = new THREE.Mesh(
          new THREE.PlaneGeometry(80, 80, 1, 1),
          new THREE.MeshBasicMaterial({
            color: 0xff00ff,
            transparent: true,
            opacity: 0,
            side: THREE.DoubleSide,
          }),
        );

        this.surface.rotateX(THREE.MathUtils.degToRad(-90));
        this.surface.position.set(0, 0, 0);
        this.surface.receiveShadow = true;

        this.scene.add(this.surface);

        // Ligths
        const dynamicLightIntensity = () => {
          const variant = Utils.getURLParameter('variant');
          const colour = Utils.getURLParameter('colour');
          let intensity;
    
          if(variant === 'collider') {
            if(colour === 'brown'){
              intensity = 6;
            } else if(colour === 'black') {
              intensity = 4;
            } else {
              intensity = 6;
            }
          }
    
          if(variant === 'monroe') {
            if(colour === 'silver'){
              intensity = 5;
            } else if(colour === 'black') {
              intensity = 15;
            } else {
              intensity = 9;
            }
          }

          if(variant === 'neutra') {
            if(colour === 'black'){
              intensity = 10;
            }  else {
              intensity = 0;
            }
          }
    
          return intensity;
        };

        const dynamicAmbient = () => {
          const variant = Utils.getURLParameter('variant');
          const colour = Utils.getURLParameter('colour');
          let intensity;
    
          if(variant === 'neutra') {
            if(colour === 'brown'){
              intensity = 1;
              const altLight = new THREE.DirectionalLight(0xffffff, 5);
              //altLight.position.set(0, 0, 3);
              this.scene.add(altLight);

              const altLight2 = new THREE.DirectionalLight(0xffffff, 2);
              altLight2.position.set(-5, 0, 3);
              this.scene.add(altLight2);

              const altLight3 = new THREE.DirectionalLight(0xffffff, 2);
              altLight3.position.set(5, 0, 3);
              this.scene.add(altLight3);
            }  else {
              intensity = 0.75;

              const altLight2 = new THREE.DirectionalLight(0xffffff, 2);
              altLight2.position.set(-5, 0, 3);
              this.scene.add(altLight2);

              const altLight3 = new THREE.DirectionalLight(0xffffff, 2);
              altLight3.position.set(5, 0, 3);
              this.scene.add(altLight3);
            }
          } else {
            intensity = 0.75;
          }
          return intensity;
        };

        this.ambLight = new THREE.AmbientLight(0xaaaaaa, dynamicAmbient());
        
        this.scene.add(this.ambLight);
        this.sunlight = new THREE.PointLight(0xffffff, dynamicLightIntensity());
        this.sunlight.position.set(0, GLOBE_Y_CENTER, 0);
        this.scene.add(this.sunlight);

        this.ring = new THREE.Mesh(
          new THREE.RingGeometry(0.6, 0.8, 32, 1),
          new THREE.MeshBasicMaterial({
            color: Palette.sand,
            side: THREE.DoubleSide,
          }),
        );
        this.ring.rotateX(THREE.MathUtils.degToRad(-90));
        this.group.add(this.ring);
        this.ring.scale.set(0.5, 0.5, 0.5);

        // Set the initial camera position relative to the scene we just laid out.
        // This must be at a height greater than y=GLOBE_Y_CENTER.
        this.camera.position.set(0, GLOBE_Y_CENTER + 1, 0);
        this.camera.lookAt(new THREE.Vector3(0, 0, 0));

        // COORDS.map((coord) => placeObjectOnPlanet(coord));
        // INNER_COORDS.map((coord, index) => placeObjectInsidePlanet(coord, index));
        COORDS_ALT.map((data) => placeObjectOnWatch(data));
        this.globe.add(this.hotspots);

        // Section caps + hotspots
        // this.scene.add(this.earthSectionLeft);
        // this.scene.add(this.earthSectionRight);
        // this.scene.add(this.innerHotspots);

        this.attachHandlers();
      };

      const updateGlobe = () => {
        const { props } = this;
        const { store } = props;
        const { app } = store;
        const { x, y, z } = this.camera.position;
        this.sunlight.position.set(x, y, z);

        const delta = this.clock.getDelta();

        if (this.mixer) {
          this.mixer.update(delta);
        }
        // this.mixer.update(time);

        if (this.globe && this.rotating === true) {
          this.globe.rotateY(THREE.MathUtils.degToRad(0.118));
        }

        if (app.globePlaced === false) {
          this.raycaster.setFromCamera(centerCamera, this.camera);
          const intersects = this.raycaster.intersectObject(this.surface);

          if (intersects.length === 1 && intersects[0].object === this.surface) {
            this.group.position.x = intersects[0].point.x;
            this.group.position.z = intersects[0].point.z;
          }
        }
      };

      const animateHotspots = () => {
        if (this.hotspots.children.length > 0) {
          this.hotspots.children.map((hot) => {
            const { animator } = hot.userData;
            animator.animate();
            return true;
          });
        }
      };

      // Mouse and resize events
      const onWindowResize = () => {
        const c = this.el.current;
        c.width = window.innerWidth;
        c.height = window.innerHeight;

        this.camera.aspect = window.innerWidth / window.innerHeight;
        // Sync the xr controller's 6DoF position and camera paremeters with our scene.
        XR8.XrController.updateCameraProjectionMatrix({
          origin: this.camera.position,
          facing: this.camera.quaternion,
        });
        this.renderer.setSize(window.innerWidth, window.innerHeight);
      };

      return {
        // Pipeline modules need a name.
        // It can be whatever you want but must be unique within your app.
        name: 'placeground',

        // onStart is called once when the camera feed begins. In this case, we need to wait for the
        // XR8.Threejs scene to be ready before we can access it to add content. It was created in
        // XR8.Threejs.pipelineModule()'s onStart method.
        onStart: ({ canvas }) => {
          const c = canvas;
          c.width = window.innerWidth;
          c.height = window.innerHeight;

          const { scene, camera, renderer } = XR8.Threejs.xrScene();
          this.canvas = scene;
          this.scene = scene;
          this.camera = camera;
          this.renderer = renderer;
          this.renderer.localClippingEnabled = false;

          initXrScene();
          window.addEventListener('resize', onWindowResize, false);

          // Enable TWEEN animations.
          function animate(time) {
            updateGlobe(time);
            animateHotspots(time);
            TweenUpdate(time);
            requestAnimationFrame(animate);
          }
          animate();

          // Sync the xr controller's 6DoF position and camera paremeters with our scene.
          XR8.XrController.updateCameraProjectionMatrix({
            origin: this.camera.position,
            facing: this.camera.quaternion,
          });
        },
      };
    };
  }

  componentDidMount() {
    ReactGA.pageview('/watch', ['eyekandy', 'internal']);
    window.THREE = THREE;

    const { globe, biomes } = this.props;

    this.earth = globe;
    this.biomes = biomes;

    this.fakeEarth = globe.clone();
    this.fakeEarth.traverse((node) => {
      const n = node;
      if (n.isMesh) {
        n.material = n.material.clone();
        if (n.name === 'ocean') {
          n.visible = false;
        }
      }
    });

    Utils.setOpacity(this.fakeEarth, 0.3);
    this.fakeEarth.position.set(0, GLOBE_Y_CENTER, 0);
    this.fakeEarth.scale.set(7.5, 7.5, 7.5);
    this.group.add(this.fakeEarth);

    this.earth.scale.set(1, 1, 1);

    this.globe.add(this.earth);
    this.globe.add(this.biomes);
    this.globe.visible = false;

    this.globe.position.set(0, GLOBE_Y_CENTER, 0);
    this.globe.scale.set(1, 1, 1);

    this.earth.rotateY(THREE.MathUtils.degToRad(-180));
    // this.biomes.rotateY(THREE.MathUtils.degToRad(-180));

    // Add section meshes
    this.earthCore = MeshHandler.createCore();
    //this.globe.add(this.earthCore);

    // Need to be detached from the group otherwise reports odd rotation quatertions.
    // Will add on init Scene and move it when spawnEarth is invoked
    this.earthSectionLeft = MeshHandler.createSectionMesh();
    this.earthSectionRight = MeshHandler.createSectionMesh();

    this.earthSectionLeft.visible = false;
    this.earthSectionRight.visible = false;
    this.innerHotspots.visible = false;
    this.earthCore.visible = false;

    this.globe.rotateY(THREE.MathUtils.degToRad(-180));

    // Biomes Animations
    // Create an AnimationMixer, and get the list of AnimationClip instances
    // this.mixer = new THREE.AnimationMixer(this.biomes);

    // const clips = this.biomes.userData.animations;

    // // Play all animations
    // const biomeRegex = /biome_([a-z]*)_/i;
    // clips.forEach((clip) => {
    //   const c = clip;
    //   const action = this.mixer.clipAction(c).setLoop(THREE.LoopOnce);
    //   const matches = c.name.match(biomeRegex);
    //   if (matches && matches[1]) {
    //     const biomeName = matches[1];
    //     this.animations[biomeName] = action;
    //   }
    // });

    this.group.add(this.globe);

    const { XR8, XRExtras } = window;

    XR8.XrController.configure({
      enableLighting: false,
      enableWorldPoints: true,
      disableWorldTracking: false,
    });

    XR8.addCameraPipelineModules([ // Add camera pipeline modules.
      // Existing pipeline modules.
      XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
      XR8.Threejs.pipelineModule(), // Creates a ThreeJS AR Scene.
      XR8.XrController.pipelineModule(), // Enables SLAM tracking.
      XR8.CanvasScreenshot.pipelineModule(), // Screenshot provider.
      XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
      // Iframe pipeline
      window.iframeInnerPipelineModule,
      // Custom pipeline modules.
      this.globePipelineModule(),
    ]);

    XR8.CanvasScreenshot.configure({
      maxDimension: 1920,
      jpgCompression: 85,
    });

    XR8.run({
      canvas: this.el.current,
      webgl2: true,
      glContextConfig: {
        antialias: true,
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const { store } = props;
    const { ui, app } = store;
    const { ui: prevUi, app: prevApp } = prevProps.store;

    // Hide Hotspot states
    if (prevUi.hideHotspots === false && ui.hideHotspots === true) {
      this.rotating = false;
      Utils.animateOpacity(this.hotspots, 1, 0);
    }

    if (prevUi.hideHotspots === true && ui.hideHotspots === false) {
      this.hotspots.visible = true;
      Utils.animateOpacity(this.hotspots, 0, 1);
    }

    // Biomes states
    if (prevUi.biome === false && ui.biome === true) {
      ReactGA.event({
        category: 'app',
        action: 'biome',
        label: app.biome,
      });
      ReactGA.pageview(`/globe/${app.biome.toLowerCase()}`, ['eyekandy', 'internal']);

      if (this.helpTimeout) {
        clearTimeout(this.helpTimeout);
        props.closeMessage(true);
        props.setHelpDisplayed();
      }

    }

    if (prevApp.section !== app.section) {
      ReactGA.event({
        category: 'app',
        action: 'interaction',
        label: 'section',
      }, ['eyekandy', 'internal']);

      this.updateSections(app.section);
    }

    if (prevUi.picture === false && ui.picture === true) {
      ReactGA.event({
        category: 'app',
        action: 'photo',
        label: 'taken',
      }, ['eyekandy', 'internal']);

      this.takeScreenshot();
    }

    if (prevApp.globePlaced === true && app.globePlaced === false) {
      const { XR8 } = window;
      const { XrController } = XR8;
      XrController.recenter();

      this.ring.visible = true;
      this.fakeEarth.visible = true;
      this.globe.visible = false;

      // Reset positions
      this.group.rotation.y = THREE.MathUtils.degToRad(0);
      this.globe.rotation.z = 0;
      this.globe.rotation.x = 0;
      this.group.rotation.y = 0;

      ReactGA.event({
        category: 'app',
        action: 'globe',
        label: 'reset',
      }, ['eyekandy', 'internal']);
    }

    // Section triggers
    // In
    if (prevUi.section === false && ui.section === true) {
      ReactGA.event({
        category: 'app',
        action: 'section',
        label: 'open',
      }, ['eyekandy', 'internal']);
      ReactGA.pageview('/globe/section', ['eyekandy', 'internal']);

      if (this.helpTimeout) {
        clearTimeout(this.helpTimeout);
        props.closeMessage(true);
        props.setHelpDisplayed();
      }

      this.updateSections(app.section, true);
      this.renderer.localClippingEnabled = true;

      // this.earth.traverse((node) => {
      //   const n = node;
      //   if (n.isMesh) {
      //     n.material.clippingPlanes = this.clipPlanes;
      //     n.material.clipIntersection = true;
      //     if (n.name === 'earth') {
      //       n.material.side = THREE.DoubleSide;
      //     }
      //   }
      // });

      this.earthSectionLeft.visible = true;
      this.earthSectionRight.visible = true;
      this.earthCore.visible = true;
      this.innerHotspots.visible = true;
    }

    // Out
    if (prevUi.section === true && ui.section === false) {
      ReactGA.event({
        category: 'app',
        action: 'section',
        label: 'close',
      }, ['eyekandy', 'internal']);
      ReactGA.pageview('/globe', ['eyekandy', 'internal']);

      this.renderer.localClippingEnabled = false;

      this.earth.traverse((node) => {
        const n = node;
        if (n.isMesh) {
          n.material.clippingPlanes = [];
          n.material.clipIntersection = false;
          if (n.name === 'earth') {
            n.material.side = THREE.FrontSide;
          }
        }
      });

      this.earthSectionLeft.visible = false;
      this.earthSectionRight.visible = false;
      this.earthCore.visible = false;
      this.innerHotspots.visible = false;
    }
  }

  render() {
    const { state, props } = this;
    const { image } = state;
    const { store } = props;

    return (
      <>
        <Canvas id="ar" ref={this.el} />
        <CSSTransition
          in={(store.ui.picture && image !== false)}
          classNames="fade-scale"
          timeout={300}
          unmountOnExit
          onExited={this.removeScreenshot}
          appear
        >
          <CameraPicture image={image} />
        </CSSTransition>
      </>
    );
  }
}

export default connect(
  (state) => ({ store: getState(state) }),
  {
    toggleSection,
    selectBiome,
    resetGlobe,
    placeGlobe,
    setMessage,
    closeMessage,
    setHelpDisplayed,
  },
)(GlobeAR);
