import React, { Component } from 'react';
import {
  Color,
  MeshLambertMaterial,
  TextureLoader,
  FrontSide,
  AudioLoader,
  MathUtils,
} from 'three';
import {
  BrowserView,
  MobileView,
  isIOS,
  isMobile,
} from 'react-device-detect';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import Fullscreen from 'react-full-screen';
import { Row, Col } from 'react-grid-system';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import GlobeHome from '../Three/GlobeHome';
import GlobeAR from '../Three/GlobeAR';
import Loading from '../Loading';
import Interface from '../Interface';
import Logo from '../Logo';

import Palette from '../../libs/Palette';

import Utils from '../Three/handlers/utils';

const LogoContainer = styled.div`
  z-index: 1000;
  position: fixed;
  max-width: 640px;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 4rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      compatible: true,
      model: null,
      biomes: null,
      playing: false,
      fullscreen: false,
      memory: false,
    };

    this.startExperience = () => {
      ReactGA.event({
        category: 'app',
        action: 'experience',
        label: 'start',
      });

      this.setState({
        playing: true,
        fullscreen: true,
      });
    };

    this.messageWrapper = (message) => {

      const currentURL = window.location.href;

      const getRetailer = () => {
        console.log(Utils.getURLParameter('retailer'));
        return Utils.getURLParameter('retailer');
      };

      const getVariant = () => {
        console.log(Utils.getURLParameter('variant'));
        return Utils.getURLParameter('variant');
      };

      const getColour = () => {
        console.log(Utils.getURLParameter('colour'));
        return Utils.getURLParameter('colour');
      };

      const abTest = () => {
        if (Utils.getURLParameter('abTest') !== undefined) {
          return '-abTest';
        } else {
          return '';
        }
      };
      
      const qrCodeLink = '/assets/images/qr-codes/qr-' + getVariant() + '-' + getColour() + '-' + getRetailer() + abTest() + '.svg';

      const Link = <a href={currentURL}>fossilar.com</a>;

      return (
        <LogoContainer>
          <Row>
            <Col xs={12}>
              <Logo />
            </Col>
          </Row>
          <BrowserView>
            <Row className="spaced-down-row-desktop">
              <Col xs={12} sm={5}>
                <Image alt="AR Fossil QR" src={qrCodeLink} />
              </Col>
              <Col xs={12} sm={7}>
              {!isMobile ? <p>
                  {'You need an AR compatible mobile device for the Fossil AR Experience, please go to '}
                  {Link}
                  {' on your device or scan the QR code.'}
                </p> : null}
              </Col>
            </Row>
          </BrowserView>
          <MobileView>
            <Row>
              <Col xs={12} sm={12}>{message}</Col>
            </Row>
          </MobileView>
        </LogoContainer>
      );
    };

    this.renderNonARBrowser = () => {
      const currentURL = window.location.href;
      const Link = <a href={currentURL}>fossilar.com</a>;

      const browser = isIOS ? 'Safari' : 'Chrome';

      const message = (
        <p>
          {'This Augmented Reality experience is currently not supported in this browser, '}
          {`please copy and paste the following link into ${browser} or tap the menu icon and press "Open in ${browser}" to start - `}
          {Link}
        </p>
      );

      return this.messageWrapper(message, false);
    };


    this.renderLowMemoryBrowser = () => {
      const message = (
        <>
          <p>
            Your device is low on memory, you can either restart your device or try to
            close all your background application and try loading this page again.
          </p>
          <p>
            If you still receive this message you might need to use a more up to date device.
          </p>
        </>
      );

      return this.messageWrapper(message, false);
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search, ['eyekandy', 'internal']);

    const load = () => {
      const { XR8 } = window;
      const compatible = XR8.XrDevice.isDeviceBrowserCompatible();
      // const compatible = false;
      this.setState({
        compatible,
      });

      ReactGA.event({
        category: 'device',
        action: 'webar_compatible',
        label: String(compatible),
        nonInteractive: true,
      });


      const loader = new GLTFLoader();
      const loaderError = (err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.setState({
          loading: false,
          memory: true,
        });

        ReactGA.event({
          category: 'error',
          action: 'device',
          label: 'memory_exhausted',
          nonInteractive: true,
        });
      };

      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(`${process.env.PUBLIC_URL}/draco/gltf/`);
      dracoLoader.preload();

      loader.setDRACOLoader(dracoLoader);

      const texture = new TextureLoader().load('/assets/textures/earth/earth-green.jpg');
      texture.flipY = false;

      const earthMaterial = new MeshLambertMaterial({
        color: 0xffffff,
        map: texture,
        side: FrontSide,
      });

      const getVariant = () => {
        console.log(Utils.getURLParameter('variant'));
        return Utils.getURLParameter('variant');
      };
      const getColour = () => {
        console.log(Utils.getURLParameter('colour'));
        return Utils.getURLParameter('colour');
      };

      const files = {
        globe: `${process.env.PUBLIC_URL}/assets/models/watch-${getVariant()}-${getColour()}.glb`,
      };
      const audioBuffers = {};

      loader.load(files.globe, (globeglb) => {
        // Extrapolate object group
        ReactGA.event({
          category: 'loaders',
          action: 'model_loaded',
          label: 'earth',
          nonInteractive: true,
        });

        const { scene: globeScene } = globeglb;
        // const [globe] = globeScene.children;
        // console.log(globeglb);

        // // Extrapolate children meshes
        // const [ocean, earth] = globe.children;

        // // Set ocean transparency on material
        // ocean.scale.set(0.97, 0.97, 0.97);
        // ocean.rotateY(MathUtils.degToRad(45)); // Quirk to hide mesh joins
        // ocean.material.side = FrontSide;
        // ocean.material.transparent = true;
        // ocean.material.opacity = 0.85;
        // ocean.material.color = new Color(Palette.deepBlue);

        // earth.material = earthMaterial;

        this.setState({
          model: globeScene,
          loading: false,
        });

      }, null, loaderError);
    };

    window.onload = () => {
      if (window.XRExtras) {
        load();
      } else {
        // If 8thwall library hasn't still loaded, wait for it.
        window.addEventListener('xrextrasloaded', load);
      }

      /**
       * The following chunk reads if the navigator XR is supported (chrome only for now)
       * and calls the appropriate loader.
       *
       * Integrate when it's fully supported, for now we fallback on 8thWall
       *  if ('xr' in navigator) {
       *    navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
       *      if (supported) {
       *        nativeAR();
       *      } else {
       *        eighthWallAR();
       *      }
       *    }).catch(eighthWallAR);
       *  }
       *
       */
    };
  }

  render() {
    const {
      model,
      biomes,
      loading,
      playing,
      fullscreen,
      compatible,
      memory,
    } = this.state;

    return (
      <>
        <BrowserView>
          {this.renderNonARBrowser()}
          <GlobeHome cameraTarget="side" globe={model} />
        </BrowserView>
        <MobileView>
          <Fullscreen
            enabled={fullscreen}
            onChange={(f) => this.setState({ fullscreen: f })}
          >
            {
              !playing
                ? (
                  <>
                    <GlobeHome cameraTarget="scene" globe={model} />
                    {
                      memory && compatible ? this.renderLowMemoryBrowser() : null
                    }
                    {
                      !compatible ? this.renderNonARBrowser() : null
                    }
                    {
                      compatible && !memory ? <Loading load={loading} onClickPlay={this.startExperience} /> : null
                    }

                  </>
                ) : (
                  <>
                    <GlobeAR globe={model} biomes={biomes} />
                    <Interface />
                  </>
                )
            }
          </Fullscreen>
        </MobileView>
      </>
    );
  }
}
