/* eslint-disable object-curly-spacing */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '../Button/index';

import { closeBiome } from '../../redux/actions';
import Utils from '../Three/handlers/utils';

const Container = styled.div`
  position: fixed;
  z-index: 1010;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
`;

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1rem;
  justify-content: flex-end;
  align-items: flex-start;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 15%, rgba(255, 255, 255, 0.8) 100%);  
  pointer-events: auto;

  h1, h2, h4 {
    margin: 0; 
    z-index: 9;       
  }
`;


const MainTitle = styled.h1`
  vertical-align: middle;
  color: var(--deep-blue);
  padding-top: 10px;
`;

const MainDescription = styled.h4`
  vertical-align: middle;
  font-size: 1em;
  font-weight: 400;
  color: var(--deep-blue);

  button {
    vertical-align: middle;
    margin-right: 0.35rem;
    font-size: 0.95em;
    padding: 0.4em;
  }  
`;

const List = styled.div`
  display: block;
  //flex-direction: column;
  align-items: left;
  width: 100%;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  &.center {
    align-items: center;
    justify-content: center;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    position: relative;
  }

  img.hero-img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }

  .gradient-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    //background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 15%, rgba(255, 255, 255, 0.8) 100%);
  }

  .fact {
    font-size: 2.15rem;
    font-weight: 400;
    line-height: 1.15;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    color: var(--deep-blue);
    padding-top: 20px;
    display: block;
    position: relative;

    b {
      color: var(--deep-blue);
    }
  }
`;

const COLLIDER = {
  Ocean: {
    interactive: true,
    title: '',
    subtitle: 'Ocean Fun Facts:',
    description: '',
    facts: [
      {
        heroImage: '/assets/images/hotspot-1.gif',
        title: 'Over <b>90%</b>',
        description: 'of the life on Earth lives in the oceans',
      },
    ],
  },
  TropicalRainforest: {
    interactive: true,
    title: '',
    subtitle: 'Tropical Rainforest Fun Facts:',
    description: '',
    facts: [
      {
        title: 'The <b>Amazon</b>',
        description: 'is the largest Tropical Rainforest',
        heroImage: '/assets/images/hotspot-2.png',
      },
    ],
  },
  TemperateRainforest: {
    interactive: true,
    title: '',
    subtitle: 'Temperate Rainforest Fun Facts:',
    description: '',
    facts: [
      {
        title: '<b>90,000</b> acorns',
        description: 'A single oak tree in this forest can produce 90,000 acorns each year!',
        heroImage: '/assets/images/hotspot-3.png',
      },
    ],
  },
  Freshwater: {
    interactive: true,
    title: '',
    subtitle: 'Freshwater Fun Facts:',
    description: '',
    facts: [
      {
        title: '<b>4,132</b> miles',
        description: 'The Nile is the longest freshwater river biome',
        heroImage: '/assets/images/hotspot-4.gif',
      },
    ],
  },
  Chaparral: {
    interactive: true,
    title: 'Mooi en Slim',
    subtitle: 'Chaparral Fun Facts:',
    description: 'Tech meets fashion! De Hybrid HR heeft de looks van een traditioneel fashion horloge en beschikt daarnaast ook over veel smartwatch-like functies, waaronder een high-tech e-ink display en personaliseerbare knoppen.',
    facts: [
      {
        title: '<b>Fire!</b>',
        description: 'Some plants in this biome have adapted to the fires, their seeds lay dormant until they are touched by fire!',
        heroImage: '/assets/images/hotspot-5.jpg',
      },
    ],
  },
};

const MONROE = {
  Ocean: {
    interactive: true,
    title: '',
    subtitle: 'Ocean Fun Facts:',
    description: '',
    facts: [
      {
        heroImage: '/assets/images/monroe/hotspot-1.gif',
        title: 'Over <b>90%</b>',
        description: 'of the life on Earth lives in the oceans',
      },
    ],
  },
  TropicalRainforest: {
    interactive: true,
    title: '',
    subtitle: 'Tropical Rainforest Fun Facts:',
    description: '',
    facts: [
      {
        title: 'The <b>Amazon</b>',
        description: 'is the largest Tropical Rainforest',
        heroImage: '/assets/images/monroe/hotspot-2.png',
      },
    ],
  },
  TemperateRainforest: {
    interactive: true,
    title: '',
    subtitle: 'Temperate Rainforest Fun Facts:',
    description: '',
    facts: [
      {
        title: '<b>90,000</b> acorns',
        description: 'A single oak tree in this forest can produce 90,000 acorns each year!',
        heroImage: '/assets/images/monroe/hotspot-3.png',
      },
    ],
  },
  Freshwater: {
    interactive: true,
    title: '',
    subtitle: 'Freshwater Fun Facts:',
    description: '',
    facts: [
      {
        title: '<b>4,132</b> miles',
        description: 'The Nile is the longest freshwater river biome',
        heroImage: '/assets/images/monroe/hotspot-4.png',
      },
    ],
  },
  Chaparral: {
    interactive: true,
    title: '',
    subtitle: 'Chaparral Fun Facts:',
    description: '',
    facts: [
      {
        title: '<b>Fire!</b>',
        description: 'Some plants in this biome have adapted to the fires, their seeds lay dormant until they are touched by fire!',
        heroImage: '/assets/images/monroe/hotspot-5.png',
      },
    ],
  },
};

const NEUTRA = {
  Ocean: {
    interactive: true,
    title: '',
    subtitle: 'Ocean Fun Facts:',
    description: '',
    facts: [
      {
        heroImage: '/assets/images/neutra/hotspot-1.gif',
        title: 'Over <b>90%</b>',
        description: 'of the life on Earth lives in the oceans',
      },
    ],
  },
  TropicalRainforest: {
    interactive: true,
    title: '',
    subtitle: 'Tropical Rainforest Fun Facts:',
    description: '',
    facts: [
      {
        title: 'The <b>Amazon</b>',
        description: 'is the largest Tropical Rainforest',
        heroImage: '/assets/images/neutra/hotspot-2.png',
      },
    ],
  },
  TemperateRainforest: {
    interactive: true,
    title: '',
    subtitle: 'Temperate Rainforest Fun Facts:',
    description: '',
    facts: [
      {
        title: '<b>90,000</b> acorns',
        description: 'A single oak tree in this forest can produce 90,000 acorns each year!',
        heroImage: '/assets/images/neutra/hotspot-3.png',
      },
    ],
  },
  Freshwater: {
    interactive: true,
    title: '',
    subtitle: 'Freshwater Fun Facts:',
    description: '',
    facts: [
      {
        title: '<b>4,132</b> miles',
        description: 'The Nile is the longest freshwater river biome',
        heroImage: '/assets/images/neutra/hotspot-4.png',
      },
    ],
  },
  Chaparral: {
    interactive: true,
    title: '',
    subtitle: 'Chaparral Fun Facts:',
    description: '',
    facts: [
      {
        title: '<b>Fire!</b>',
        description: 'Some plants in this biome have adapted to the fires, their seeds lay dormant until they are touched by fire!',
        heroImage: '/assets/images/neutra/hotspot-5.png',
      },
    ],
  },
};

class Biome extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
    };

    this.toggleShow = () => {
      const { show } = this.state;
      this.setState({
        show: !show,
      });
    };
  }

  render() {
    const { props, state } = this;
    const { biome } = props;
    const variant = Utils.getURLParameter('variant');
    let mode;
    
    if (variant === 'collider') {
      mode = COLLIDER;
    } else if (variant === 'monroe') {
      mode = MONROE;
    } else if (variant === 'neutra') {
      mode = NEUTRA;
    }

    const b = mode[biome];
    const { show } = state;
    console.log(b);

    return (
      <Container>
        <Slider
          className={`peek-down ${show ? 'show-full' : 'show-partial'}`}>
          <Button right bigHotspot={b.title === 'Mooi en Slim'} icon="close" onClick={() => props.closeBiome()} />
          {b.title !== '' ? 
          <MainTitle>
            {b.title}            
          </MainTitle> : null}
          
          {b.description !== '' ? 
          <MainDescription>
            {b.description}            
          </MainDescription> : null}
          {
            b.facts.map((fact, index) => {
              const { title, description, heroImage } = fact;
              const key = `biome-fact-${index}`;

              return (
                <List key={key}>
                  <img className="hero-img" src={heroImage} />
                  <div className="gradient-bg"></div>
                  {/* <h1
                    className="fact"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{__html: title}}
                  />
                  <p>{description}</p> */}
                </List>
              );
            })
          }
          {/* <List key="biome-description">
            <p>{b.description}</p>
          </List> */}
          {/* {
            b.interactive
              ? (
                <List key="biome-button" className="center">
                  <Button icon="tap" onClick={this.toggleShow} />
                  <span>You can tap on the biome to interact with it</span>
                </List>
              ) : null
          } */}
        </Slider>
      </Container>
    );
  }
}

export default connect(
  null,
  {
    closeBiome,
  },
)(Biome);
